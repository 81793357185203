import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavigationBar = ({ lang }) => {
    let content = {
        ES: {
          people: 'Personas',
          family: 'Familia',
          humanFigure: 'Figura humana',
          travel: 'Viajes',
          realestate: 'Inmueble',
          testimony: 'Testimonios',
          aboutme: 'Acerca de mi'
        },
        EN: {
          people: 'People',
          family: 'Family',
          humanFigure: "Human figure",
          travel: 'Travel',
          realestate: 'Real Estate',
          testimony: 'Testimonies',
          aboutme: 'About me'
        }
      };

     lang === 'ES' ? (content = content.ES ) : (content = content.EN );
     
    return (
 
        <>
            <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">Art of Bäch | Portfolio</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link href="/people">{content.people}</Nav.Link>
                <Nav.Link href="/family">{content.family}</Nav.Link>
                <Nav.Link href="/humanfigure">{content.humanFigure}</Nav.Link>              
                <Nav.Link href="/travel">{content.travel}</Nav.Link>
                <Nav.Link href="/aboutme">{content.aboutme}</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </>
    )


}

export default NavigationBar;