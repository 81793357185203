  import React from 'react';
  import { ProGallery } from 'pro-gallery';
  import 'pro-gallery/dist/statics/main.css';
  import { SRLWrapper, useLightbox } from "simple-react-lightbox";

  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  // receives an array with the images that needs to be rendered
  // the array contains objets on the following format
  // const obj = {
  //  src: Image path via a require
  //  thumbnail: Thumbnail path via require
  //  caption: Image caption
  //  category: []
  // }
  
  const Gallery = ({images, title, description}) => {

    // Hack para cambiar el fondo
    document.body.className = 'gallery-body';

    const { openLightbox, } = useLightbox();

    // Adding images {
    const items = [];

    images.map((element) => (
      items.push({
        itemId: element.id,
        src: element.src.default,
        mediaUrl: element.src.default,
        metaData: {
          type: 'image',
          title: 'sample-title',
        },
      })
    ));
    // } Adding images

    // Gallery configuration {
    // The options of the gallery (from the playground current state)
    const options = {
      galleryLayout: 0,
      imageMargin: 2,
      //hoveringBehaviour: 'NEVER_SHOW',
    };

    // The size of the gallery container. The images will fit themselves in it
    const container = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    // LightBox configuratuib
    const lightBoxoptions = {
      buttons: {
        backgroundColor: 'rgba(30,30,36,0.8)',
        iconColor: 'rgba(255, 255, 255, 0.8)',
        iconPadding: '10px',
        showAutoplayButton: true,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: true,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: true,
        size: '40px'
      }
  }  
    // The eventsListener will notify you anytime something has happened in the gallery.
    const eventsListener = (eventName, eventData) => {
      if ( eventName === "ITEM_CLICKED") {
        // open the lightbox
        openLightbox(eventData["idx"]);
      }
    }

    // The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
    const scrollingElement = window;
    // } Gallery configuration

     // handle reload on resize {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })
    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize)

        return _ => { window.removeEventListener('resize', debouncedHandleResize) }
    });
    // } reload on resize

    return (
      <>
        <h4 className="gallery-title">{title}</h4>
        <p className="gallery-description">{description}</p>
        <ProGallery
          items={items}
          options={options}
          container={container}
          eventsListener={eventsListener}
          scrollingElement={scrollingElement}
        />
        <SRLWrapper elements={items} options={lightBoxoptions} />
      </>
    );
  }

  export default Gallery;
