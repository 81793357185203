import {Container, Row, Col} from "react-bootstrap";
import BackgroundSlider from 'react-background-slider';
import FeaturedPortfolio from '../portfolio/FeaturedPortfolio';
import ReactGA from 'react-ga';


const Home = () => {

    ReactGA.event({
        category: 'LandedOn: /Home',
        action: 'Landed On',
    });

    const images = [];
    FeaturedPortfolio.imageArray.map((element) => (
        images.push(element.src.default)
    ));
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color              
            }}
        />
    );

    return (
        <>
            <BackgroundSlider
                images={images}
                duration={8} transition={1.5} />

            <div className="fade-out" >
            <Container className="d-flex h-50 fade-out" >
                <Row className="m-auto">
                    <Col className="special-card-box" >
                        
                            <h3 className="special-card-title">Art of Bäch</h3>
                            <p className="special-card-text">
                                Soy un fotógrafo basado en Chile.<br/>
                                Me inspira la luz con su capacidad de reflejar la belleza, pero sobretodo el corazón y esencia de los seres vivos.<br />
                                Mi desafío es ser capaz capturar esos momentos con mi lente.
                            </p>
                            <ColoredLine color="white"/>
                            <Row>
                                <Col><ReactGA.OutboundLink eventLabel="instagram" to="https://www.instagram.com/artofbach" target="_blank"><i style={{ color: '#FFF' }} className="fab fa-instagram fa-2x"></i></ReactGA.OutboundLink></Col>
                                <Col><ReactGA.OutboundLink eventLabel="facebook" to="https://www.facebook.com/artofbach" target="_blank"><i style={{ color: '#FFF' }} className="fab fa-facebook-square fa-2x"></i></ReactGA.OutboundLink></Col>
                                <Col><ReactGA.OutboundLink eventLabel="telegram" to="https://telegram.me/artofbach" target="_blank"><i style={{ color: '#FFF' }} className="fab fa-telegram fa-2x"></i></ReactGA.OutboundLink></Col>
                                <Col><ReactGA.OutboundLink eventLabel="email" to="mailto:artofbachler@gmail.com" target="_blank"><i style={{ color: '#FFF' }} className="far fa-envelope fa-2x"></i></ReactGA.OutboundLink></Col>
                                <Col>&nbsp;</Col>
                                <Col>&nbsp;</Col>
                                <Col>&nbsp;</Col>
                            </Row>
                    </Col>
                </Row>  
            </Container>
            </div>
        </>
    )
}

export default Home;
