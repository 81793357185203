import Gallery from './Gallery';
import ReactGA from 'react-ga';

const Family = () => {
  ReactGA.event({
    category: 'LandedOn: /family',
    action: 'Landed On',
  });

    const familyArray = [];
    const featFamily = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
    
    featFamily.forEach(element => {
      const obj = {
        key: element.id,
        src: require('../images/2020_familia/p' + element + '.jpg'),
        thumbnail: require('../images/2020_familia/p' + element + '.jpg'),
        caption: 'p' + element + '.jpg',
        category: ["familia"]
      };
      familyArray.push(obj);  
    });
    
    return (
      <Gallery images={familyArray} title={"Familia"} description={""}/>
    )
}

export default Family;