import shuffle from '../util/shuffle'

let imageArray = [];

const selectedPhotosArray = [];
for(var i = 1; i < 24; i++) {
  const obj = {
    key: i,
    src: require('../images/featured/p' + i + '.jpg'),
    thumbnail: require('../images/featured/p' + i + '.jpg'),
    caption: 'p' + i + '.jpg',
    category: ["featured"]
  }
  selectedPhotosArray.push(obj);
};


imageArray = shuffle(selectedPhotosArray);
//console.log("arrayIMages: " + JSON.stringify(imageArray))

const projectData = {
  imageArray: (imageArray),
}

export default projectData;
