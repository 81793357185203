import { Route } from 'react-router-dom';
import Home from './components/Home';
import People from './portfolio/People';
import HumanFigure from './portfolio/HumanFigure';
import Travel from './portfolio/Travel';
import Family from './portfolio/Family';
import About from './components/About';

const Routes = () => {

    return (

        <div>
            <Route exact path="/" component={Home} />       
            <Route path="/people" component={People}/>
            <Route path="/family" component={Family} />
            <Route path="/humanfigure" component={HumanFigure}/>
            <Route path="/travel" component={Travel}/>
            <Route path="/aboutme" component={About }/>
            
            <Route path="/realestate" render={() =>
                <h3>Inmueble</h3>
            }
            />
            <Route path="/testimony" render={() =>
                <h3>Testimonios</h3>
            }
            />
        </div>

    )
}

export default Routes;