import Gallery from './Gallery';
import ReactGA from 'react-ga';

const HumanFigure = () => {
    ReactGA.event({
      category: 'LandedOn: /humanfigure',
      action: 'Landed On',
    });
    
    const humanFigureArray = [];
    const featHumanFigure = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
    
    featHumanFigure.forEach(element => {
      const obj = {
        key: element.id,
        src: require('../images/2020_figura_humana/p' + element + '.jpg'),
        thumbnail: require('../images/2020_figura_humana/p' + element + '.jpg'),
        caption: 'p' + element + '.jpg',
        category: ["figura humana"]
      };
      humanFigureArray.push(obj);  
    });
    
    return (
      <Gallery images={humanFigureArray} title={"Figura humana"} description={""}/>
    )

  } 
  
  
  export default HumanFigure;
  