import Gallery from './Gallery';
import ReactGA from 'react-ga';

const Travel = () => {
    ReactGA.event({
      category: 'LandedOn: /travel',
      action: 'Landed On',
    });
    
    const travelArray = [];
    const featTravel = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
    
    featTravel.forEach(element => {
      const obj = {
        key: element.id,
        src: require('../images/2020_travel/p' + element + '.jpg'),
        thumbnail: require('../images/2020_travel/p' + element + '.jpg'),
        caption: 'p' + element + '.jpg',
        category: ["travel"]
      };
      travelArray.push(obj);  
    });
    
    return (
        <>
        <Gallery images={travelArray} title={"Viajes"} description={"Fotografías que he ido tomando en diferentes viajes en los últimos años"}/>
        </>
    )
}

export default Travel;