import Gallery from './Gallery';
import ReactGA from 'react-ga';

const People = () => {
    ReactGA.event({
      category: 'LandedOn: /people',
      action: 'Landed On',
    });
    
    const peopleArray = [];
    const featPeople = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33];
    
    featPeople.forEach(element => {
      const obj = {
        key: element.id,
        src: require('../images/2020_personas/p' + element + '.jpg'),
        thumbnail: require('../images/2020_personas/p' + element + '.jpg'),
        caption: 'p' + element + '.jpg',
        category: ["people"]
      };
      peopleArray.push(obj);  
    });
    
    return (
      <Gallery images={peopleArray} title={"Personas"} description={"Fotos de diferentes personas que he conocido en mi vida. Hay una recopilación desde que comencé el 2018 hasta hoy."}/>
    )
}

export default People;