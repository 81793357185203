import { Container, Row, Col } from "react-bootstrap";
import {Image} from "react-bootstrap";
import ReactGA from 'react-ga';

const About = () => {
    ReactGA.event({
        category: 'LandedOn: /aboutme',
        action: 'Landed On',
      });

    document.body.className = 'about-body';

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color
            }}
        />
    );

    return (
        <>
            <Container>
                <Row><Col lg="12">&nbsp;</Col></Row>
                <Row className="m-auto">
                    <Col lg="12" className="special-card-box" >

                        <h3 className="special-card-title-dark"><strong>Art of Bäch</strong> (en construcción)</h3>
                        <p className="special-card-text-dark">
                            Bienvenido a mi portfolio!
                <br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.                I'm a photographer, avid wanderer, and a software engineer.
                        </p>
                        <ColoredLine color="white" />
                        <Row>
                            <Col><ReactGA.OutboundLink eventLabel="instagram" to="https://www.instagram.com/artofbach" target="_blank"><i style={{ color: '#777' }} className="fab fa-instagram fa-2x"></i></ReactGA.OutboundLink></Col>
                            <Col><ReactGA.OutboundLink eventLabel="facebook" to="https://www.facebook.com/artofbach" target="_blank"><i style={{ color: '#777' }} className="fab fa-facebook-square fa-2x"></i></ReactGA.OutboundLink></Col>
                            <Col><ReactGA.OutboundLink eventLabel="telegram" to="https://telegram.me/artofbach" target="_blank"><i style={{ color: '#777' }} className="fab fa-telegram fa-2x"></i></ReactGA.OutboundLink></Col>
                            <Col><ReactGA.OutboundLink eventLabel="email" to="mailto:artofbachler@gmail.com" target="_blank"><i style={{ color: '#777' }} className="far fa-envelope fa-2x"></i></ReactGA.OutboundLink></Col>
                            <Col>&nbsp;</Col>
                            <Col>&nbsp;</Col>
                            <Col>&nbsp;</Col>
                        </Row>
                    </Col>

                </Row>
                <Row><Col lg="12">&nbsp;</Col></Row>
                <Row>
                    <Col lg="4">
                        <Image src={require("../images/about/FotoJorge.jpg").default} fluid />
                    </Col>
                    <Col lg="7">

                        <h2 className="font-weight-bold mb-3 p-0"> <i className="fas fa-camera-retro"></i>&nbsp;<strong>Fotógrafo</strong></h2>
                        <p className="">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br />
                        </p>

                        <h4 className="tech-info"> My Camera Gear</h4>
                        <ul style={{ marginLeft: '1em' }}>
                            <li className="camera-info-list"> Canon 5D mark IV</li>
                            <li className="camera-info-list"> Canon 85mm 1.2L</li>
                            <li className="camera-info-list"> Sigma Af 24-70mm Art F2.8</li>
                        </ul>

                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default About;