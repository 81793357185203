import Headroom from 'react-headroom';
import NavigationBar from './components/NavigationBar';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';

const App = () => {
  document.body.className = 'home-body';

  return (
    <SimpleReactLightbox>
      <Headroom>
        <NavigationBar lang='ES'/>
      </Headroom>  
      <BrowserRouter>
        <Routes />
      </BrowserRouter>    
    </SimpleReactLightbox>
  )
} 

export default App;
